@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

.chakra-petch-light {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .chakra-petch-regular {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .chakra-petch-medium {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .chakra-petch-semibold {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .chakra-petch-bold {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .chakra-petch-light-italic {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .chakra-petch-regular-italic {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .chakra-petch-medium-italic {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .chakra-petch-semibold-italic {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .chakra-petch-bold-italic {
    font-family: "Chakra Petch", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  