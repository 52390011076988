.hexgrid {
    display:flex;
    --s: 100px;  /* size  */
    --m: 4px;    /* margin */
    --f: calc(1.732 * var(--s) + 4 * var(--m)  - 1px);
  
  
  .hextainer {
    font-size: 0; /*disable white space between inline block element */
  }
  
  .hextainer div {
    width: var(--s);
    margin: var(--m);
    height: calc(var(--s)*1.1547); 
    display: inline-block;
    font-size:initial;
    clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
    background: #f5f5f5;
    margin-bottom: calc(var(--m) - var(--s)*0.2885); 

  }
  .hextainer div:nth-child(odd) {
    background:#ececec;
  }
  .hextainer::before {
    content: "";
    width: calc(var(--s)/2 + var(--m));
    float: left;
    height: 120%;
    shape-outside: repeating-linear-gradient(     
                     #0000 0 calc(var(--f) - 3px),      
                     #000  0 var(--f));
  }
  
}