@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/hexgrid';
@import 'scss/hexnet';
@import 'scss/icons';

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

.bg-white {
  background-color: #fff;
}

.bg-purple {
  background-color: #342741;
}

.svg {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  z-index: 22;
}