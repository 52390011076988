@import "https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

.chakra-petch-light {
  font-family: Chakra Petch, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.chakra-petch-regular {
  font-family: Chakra Petch, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.chakra-petch-medium {
  font-family: Chakra Petch, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.chakra-petch-semibold {
  font-family: Chakra Petch, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.chakra-petch-bold {
  font-family: Chakra Petch, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.chakra-petch-light-italic {
  font-family: Chakra Petch, sans-serif;
  font-style: italic;
  font-weight: 300;
}

.chakra-petch-regular-italic {
  font-family: Chakra Petch, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.chakra-petch-medium-italic {
  font-family: Chakra Petch, sans-serif;
  font-style: italic;
  font-weight: 500;
}

.chakra-petch-semibold-italic {
  font-family: Chakra Petch, sans-serif;
  font-style: italic;
  font-weight: 600;
}

.chakra-petch-bold-italic {
  font-family: Chakra Petch, sans-serif;
  font-style: italic;
  font-weight: 700;
}

.hex-grid {
  justify-content: center;
  display: flex;
}

.hex-grid__list {
  grid-template-columns: repeat(var(--amount), 1fr 2fr) 1fr;
  grid-gap: 2.5rem 5rem;
  --amount: 5;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  position: relative;
}

.hex-grid__item {
  grid-column: 1 / span 3;
  grid-row: calc(var(--counter)  + var(--counter)) / span 2;
  filter: drop-shadow(0 0 10px #44444414);
  height: 0;
  padding-bottom: 90%;
  position: relative;
}

.hex-grid__content {
  color: #111;
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 25%;
  font-size: 1.125rem;
  text-decoration: none;
  transition: transform .24s ease-out;
  display: flex;
  position: absolute;
}

@media screen and (width >= 1440px) {
  .hex-grid__list {
    --amount: 5;
    --counter: 1;
  }

  .hex-grid__item:nth-of-type(5n+1) {
    grid-column: 1 / span 3;
  }

  .hex-grid__item:nth-of-type(5n+2) {
    grid-column: 3 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(5n+3) {
    grid-column: 5 / span 3;
  }

  .hex-grid__item:nth-of-type(5n+4) {
    grid-column: 7 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(5n+5) {
    grid-column: 9 / span 3;
  }

  .hex-grid__item:nth-of-type(n+6) {
    --counter: 2;
  }

  .hex-grid__item:nth-of-type(n+11) {
    --counter: 3;
  }

  .hex-grid__item:nth-of-type(n+16) {
    --counter: 4;
  }

  .hex-grid__item:nth-of-type(n+21) {
    --counter: 5;
  }

  .hex-grid__item:nth-of-type(n+26) {
    --counter: 6;
  }

  .hex-grid__item:nth-of-type(n+31) {
    --counter: 7;
  }

  .hex-grid__item:nth-of-type(n+36) {
    --counter: 8;
  }

  .hex-grid__item:nth-of-type(n+41) {
    --counter: 9;
  }

  .hex-grid__item:nth-of-type(n+46) {
    --counter: 10;
  }

  .hex-grid__item:nth-of-type(n+51) {
    --counter: 11;
  }

  .hex-grid__item:nth-of-type(n+56) {
    --counter: 12;
  }

  .hex-grid__item:nth-of-type(n+61) {
    --counter: 13;
  }

  .hex-grid__item:nth-of-type(n+66) {
    --counter: 14;
  }

  .hex-grid__item:nth-of-type(n+71) {
    --counter: 15;
  }

  .hex-grid__item:nth-of-type(n+76) {
    --counter: 16;
  }

  .hex-grid__item:nth-of-type(n+81) {
    --counter: 17;
  }

  .hex-grid__item:nth-of-type(n+86) {
    --counter: 18;
  }

  .hex-grid__item:nth-of-type(n+91) {
    --counter: 19;
  }

  .hex-grid__item:nth-of-type(n+96) {
    --counter: 20;
  }

  .hex-grid__item:nth-of-type(n+101) {
    --counter: 21;
  }
}

@media screen and (width >= 1120px) and (width <= 1439px) {
  .hex-grid__list {
    --amount: 4;
    --counter: 1;
  }

  .hex-grid__item:nth-of-type(4n+1) {
    grid-column: 1 / span 3;
  }

  .hex-grid__item:nth-of-type(4n+2) {
    grid-column: 3 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(4n+3) {
    grid-column: 5 / span 3;
  }

  .hex-grid__item:nth-of-type(4n+4) {
    grid-column: 7 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(n+5) {
    --counter: 2;
  }

  .hex-grid__item:nth-of-type(n+9) {
    --counter: 3;
  }

  .hex-grid__item:nth-of-type(n+13) {
    --counter: 4;
  }

  .hex-grid__item:nth-of-type(n+17) {
    --counter: 5;
  }

  .hex-grid__item:nth-of-type(n+21) {
    --counter: 6;
  }

  .hex-grid__item:nth-of-type(n+25) {
    --counter: 7;
  }

  .hex-grid__item:nth-of-type(n+29) {
    --counter: 8;
  }

  .hex-grid__item:nth-of-type(n+33) {
    --counter: 9;
  }

  .hex-grid__item:nth-of-type(n+37) {
    --counter: 10;
  }

  .hex-grid__item:nth-of-type(n+41) {
    --counter: 11;
  }

  .hex-grid__item:nth-of-type(n+45) {
    --counter: 12;
  }

  .hex-grid__item:nth-of-type(n+49) {
    --counter: 13;
  }

  .hex-grid__item:nth-of-type(n+53) {
    --counter: 14;
  }

  .hex-grid__item:nth-of-type(n+57) {
    --counter: 15;
  }

  .hex-grid__item:nth-of-type(n+61) {
    --counter: 16;
  }

  .hex-grid__item:nth-of-type(n+65) {
    --counter: 17;
  }

  .hex-grid__item:nth-of-type(n+69) {
    --counter: 18;
  }

  .hex-grid__item:nth-of-type(n+73) {
    --counter: 19;
  }

  .hex-grid__item:nth-of-type(n+77) {
    --counter: 20;
  }

  .hex-grid__item:nth-of-type(n+81) {
    --counter: 21;
  }
}

@media screen and (width >= 840px) and (width <= 1119px) {
  .hex-grid__list {
    grid-gap: 1.5rem 3rem;
    --amount: 3;
    --counter: 1;
  }

  .hex-grid__item:nth-of-type(3n+1) {
    grid-column: 1 / span 3;
  }

  .hex-grid__item:nth-of-type(3n+2) {
    grid-column: 3 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(3n+3) {
    grid-column: 5 / span 3;
  }

  .hex-grid__item:nth-of-type(n+4) {
    --counter: 2;
  }

  .hex-grid__item:nth-of-type(n+7) {
    --counter: 3;
  }

  .hex-grid__item:nth-of-type(n+10) {
    --counter: 4;
  }

  .hex-grid__item:nth-of-type(n+13) {
    --counter: 5;
  }

  .hex-grid__item:nth-of-type(n+16) {
    --counter: 6;
  }

  .hex-grid__item:nth-of-type(n+19) {
    --counter: 7;
  }

  .hex-grid__item:nth-of-type(n+22) {
    --counter: 8;
  }

  .hex-grid__item:nth-of-type(n+25) {
    --counter: 9;
  }

  .hex-grid__item:nth-of-type(n+28) {
    --counter: 10;
  }

  .hex-grid__item:nth-of-type(n+31) {
    --counter: 11;
  }

  .hex-grid__item:nth-of-type(n+34) {
    --counter: 12;
  }

  .hex-grid__item:nth-of-type(n+37) {
    --counter: 13;
  }

  .hex-grid__item:nth-of-type(n+40) {
    --counter: 14;
  }

  .hex-grid__item:nth-of-type(n+43) {
    --counter: 15;
  }

  .hex-grid__item:nth-of-type(n+46) {
    --counter: 16;
  }

  .hex-grid__item:nth-of-type(n+49) {
    --counter: 17;
  }

  .hex-grid__item:nth-of-type(n+52) {
    --counter: 18;
  }

  .hex-grid__item:nth-of-type(n+55) {
    --counter: 19;
  }

  .hex-grid__item:nth-of-type(n+58) {
    --counter: 20;
  }

  .hex-grid__item:nth-of-type(n+61) {
    --counter: 21;
  }
}

@media screen and (width >= 480px) and (width <= 839px) {
  .hex-grid__list {
    grid-gap: 1.5rem 3rem;
    --amount: 2;
    --counter: 1;
  }

  .hex-grid__item:nth-of-type(odd) {
    grid-column: 1 / span 3;
  }

  .hex-grid__item:nth-of-type(2n+2) {
    grid-column: 3 / span 3;
    grid-row: calc(var(--counter)  + var(--counter)  - 1) / span 2;
  }

  .hex-grid__item:nth-of-type(n+3) {
    --counter: 2;
  }

  .hex-grid__item:nth-of-type(n+5) {
    --counter: 3;
  }

  .hex-grid__item:nth-of-type(n+7) {
    --counter: 4;
  }

  .hex-grid__item:nth-of-type(n+9) {
    --counter: 5;
  }

  .hex-grid__item:nth-of-type(n+11) {
    --counter: 6;
  }

  .hex-grid__item:nth-of-type(n+13) {
    --counter: 7;
  }

  .hex-grid__item:nth-of-type(n+15) {
    --counter: 8;
  }

  .hex-grid__item:nth-of-type(n+17) {
    --counter: 9;
  }

  .hex-grid__item:nth-of-type(n+19) {
    --counter: 10;
  }

  .hex-grid__item:nth-of-type(n+21) {
    --counter: 11;
  }

  .hex-grid__item:nth-of-type(n+23) {
    --counter: 12;
  }

  .hex-grid__item:nth-of-type(n+25) {
    --counter: 13;
  }

  .hex-grid__item:nth-of-type(n+27) {
    --counter: 14;
  }

  .hex-grid__item:nth-of-type(n+29) {
    --counter: 15;
  }

  .hex-grid__item:nth-of-type(n+31) {
    --counter: 16;
  }

  .hex-grid__item:nth-of-type(n+33) {
    --counter: 17;
  }

  .hex-grid__item:nth-of-type(n+35) {
    --counter: 18;
  }

  .hex-grid__item:nth-of-type(n+37) {
    --counter: 19;
  }

  .hex-grid__item:nth-of-type(n+39) {
    --counter: 20;
  }

  .hex-grid__item:nth-of-type(n+41) {
    --counter: 21;
  }
}

@media screen and (width <= 479px) {
  .hex-grid__list {
    grid-gap: 1.5rem 3rem;
    --amount: 1;
  }
}

.hexgrid {
  --s: 100px;
  --m: 4px;
  --f: calc(1.732 * var(--s)  + 4 * var(--m)  - 1px);
  display: flex;
}

.hexgrid .hextainer {
  font-size: 0;
}

.hexgrid .hextainer div {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  font-size: initial;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  margin-bottom: calc(var(--m)  - var(--s) * .2885);
  background: #f5f5f5;
  display: inline-block;
}

.hexgrid .hextainer div:nth-child(odd) {
  background: #ececec;
}

.hexgrid .hextainer:before {
  content: "";
  width: calc(var(--s) / 2 + var(--m));
  float: left;
  shape-outside: repeating-linear-gradient(#0000 0 calc(var(--f)  - 3px), #000 0 var(--f));
  height: 120%;
}

.icon {
  vertical-align: -.15em;
  width: .8em;
  height: .8em;
  display: inline-block;
  overflow: hidden;
}

.icon-external {
  mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"currentColor\" viewBox=\"0 0 512 512\"><path d=\"M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z\"/></svg>") no-repeat;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, [data-theme] {
  background-color: var(--fallback-b1, oklch(var(--b1) / 1));
  color: var(--fallback-bc, oklch(var(--bc) / 1));
}

@supports not (color: oklch(0% 0 0)) {
  :root {
    color-scheme: light;
    --fallback-p: #491eff;
    --fallback-pc: #d4dbff;
    --fallback-s: #ff41c7;
    --fallback-sc: #fff9fc;
    --fallback-a: #00cfbd;
    --fallback-ac: #00100d;
    --fallback-n: #2b3440;
    --fallback-nc: #d7dde4;
    --fallback-b1: #fff;
    --fallback-b2: #e5e6e6;
    --fallback-b3: #e5e6e6;
    --fallback-bc: #1f2937;
    --fallback-in: #00b3f0;
    --fallback-inc: #000;
    --fallback-su: #00ca92;
    --fallback-suc: #000;
    --fallback-wa: #ffc22d;
    --fallback-wac: #000;
    --fallback-er: #ff6f70;
    --fallback-erc: #000;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: dark;
      --fallback-p: #7582ff;
      --fallback-pc: #050617;
      --fallback-s: #ff71cf;
      --fallback-sc: #190211;
      --fallback-a: #00c7b5;
      --fallback-ac: #000e0c;
      --fallback-n: #2a323c;
      --fallback-nc: #a6adbb;
      --fallback-b1: #1d232a;
      --fallback-b2: #191e24;
      --fallback-b3: #15191e;
      --fallback-bc: #a6adbb;
      --fallback-in: #00b3f0;
      --fallback-inc: #000;
      --fallback-su: #00ca92;
      --fallback-suc: #000;
      --fallback-wa: #ffc22d;
      --fallback-wac: #000;
      --fallback-er: #ff6f70;
      --fallback-erc: #000;
    }
  }
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --in: 72.06% .191 231.6;
    --su: 64.8% .15 160;
    --wa: 84.71% .199 83.87;
    --er: 71.76% .221 22.18;
    --pc: 13.138% .0392 275.75;
    --sc: 14.96% .052 342.55;
    --ac: 14.902% .0334 183.61;
    --inc: 0% 0 0;
    --suc: 0% 0 0;
    --wac: 0% 0 0;
    --erc: 0% 0 0;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 65.69% .196 275.75;
    --s: 74.8% .26 342.55;
    --a: 74.51% .167 183.61;
    --n: 31.3815% .021108 254.139;
    --nc: 74.6477% .0216 264.436;
    --b1: 25.3267% .015896 252.418;
    --b2: 23.2607% .013807 253.101;
    --b3: 21.1484% .01165 254.088;
    --bc: 74.6477% .0216 264.436;
  }
}

[data-theme="light"] {
  color-scheme: light;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 89.824% .06192 275.75;
  --ac: 15.352% .0368 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 49.12% .3096 275.75;
  --s: 69.71% .329 342.55;
  --sc: 98.71% .0106 342.55;
  --a: 76.76% .184 183.61;
  --n: 32.1785% .02476 255.702;
  --nc: 89.4994% .011585 252.096;
  --b1: 100% 0 0;
  --b2: 96.1151% 0 0;
  --b3: 92.4169% .00108 197.138;
  --bc: 27.8078% .029596 256.848;
}

[data-theme="dark"] {
  color-scheme: dark;
  --in: 72.06% .191 231.6;
  --su: 64.8% .15 160;
  --wa: 84.71% .199 83.87;
  --er: 71.76% .221 22.18;
  --pc: 13.138% .0392 275.75;
  --sc: 14.96% .052 342.55;
  --ac: 14.902% .0334 183.61;
  --inc: 0% 0 0;
  --suc: 0% 0 0;
  --wac: 0% 0 0;
  --erc: 0% 0 0;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 65.69% .196 275.75;
  --s: 74.8% .26 342.55;
  --a: 74.51% .167 183.61;
  --n: 31.3815% .021108 254.139;
  --nc: 74.6477% .0216 264.436;
  --b1: 25.3267% .015896 252.418;
  --b2: 23.2607% .013807 253.101;
  --b3: 21.1484% .01165 254.088;
  --bc: 74.6477% .0216 264.436;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (hover: hover) {
  .label a:hover {
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  .menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--rounded-btn, .5rem);
  border-color: #0000;
  border-color: oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity));
  text-align: center;
  border-width: var(--border-btn, 1px);
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
  background-color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, opacity, box-shadow, transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled], .btn:disabled {
  pointer-events: none;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

:where(.btninput[type="checkbox"]), :where(.btninput[type="radio"]) {
  appearance: none;
  width: auto;
}

.btninput[type="checkbox"]:after, .btninput[type="radio"]:after {
  content: var(--tw-content);
  --tw-content: attr(aria-label);
}

.checkbox {
  cursor: pointer;
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  --chkbg: var(--fallback-bc, oklch(var(--bc) / 1));
  --chkfg: var(--fallback-b1, oklch(var(--b1) / 1));
  --tw-border-opacity: .2;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.drawer {
  grid-auto-columns: max-content auto;
  width: 100%;
  display: grid;
  position: relative;
}

.drawer-content {
  grid-row-start: 1;
  grid-column-start: 2;
  min-width: 0;
}

.drawer-side {
  pointer-events: none;
  overscroll-behavior: contain;
  inset-inline-start: 0;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-row-start: 1;
  grid-column-start: 1;
  place-items: flex-start start;
  width: 100%;
  height: 100dvh;
  display: grid;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.drawer-side > .drawer-overlay {
  cursor: pointer;
  background-color: #0000;
  place-self: stretch stretch;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  position: sticky;
  top: 0;
}

.drawer-side > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.drawer-side > :not(.drawer-overlay) {
  will-change: transform;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
  transform: translateX(-100%);
}

[dir="rtl"] .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

.drawer-toggle {
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

.drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overflow-y: auto;
}

.drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-end .drawer-toggle ~ .drawer-content {
  grid-column-start: 1;
}

.drawer-end .drawer-toggle ~ .drawer-side {
  grid-column-start: 2;
  justify-items: end;
}

.drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(100%);
}

[dir="rtl"] .drawer-end .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(-100%);
}

.drawer-end .drawer-toggle:checked ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

@media (hover: hover) {
  .btn:hover {
    border-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-border-opacity)));
    background-color: var(--fallback-b3, oklch(var(--b3) / var(--tw-bg-opacity)));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn:hover {
      background-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity, 1)) 90%, black);
      border-color: color-mix(in oklab, oklch(var(--btn-color, var(--b2)) / var(--tw-border-opacity, 1)) 90%, black);
    }
  }

  @supports not (color: oklch(0% 0 0)) {
    .btn:hover {
      background-color: var(--btn-color, var(--fallback-b2));
      border-color: var(--btn-color, var(--fallback-b2));
    }
  }

  .btn.glass:hover {
    --glass-opacity: 25%;
    --glass-border-opacity: 15%;
  }

  .btn-ghost:hover {
    border-color: #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    .btn-ghost:hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .2));
    }
  }

  .btn-outline:hover {
    border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
    background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
    color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
  }

  .btn-outline.btn-primary:hover {
    color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-primary:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-secondary:hover {
    color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-secondary:hover {
      background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-accent:hover {
    color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-accent:hover {
      background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-success:hover {
    color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-success:hover {
      background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-info:hover {
    color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-info:hover {
      background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-warning:hover {
    color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-warning:hover {
      background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    }
  }

  .btn-outline.btn-error:hover {
    color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
    --tw-text-opacity: 1;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btn-outline.btn-error:hover {
      background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    }
  }

  .btn-disabled:hover, .btn[disabled]:hover, .btn:disabled:hover {
    background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
    color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
    --tw-border-opacity: 0;
    --tw-bg-opacity: .2;
    --tw-text-opacity: .2;
  }

  @supports (color: color-mix(in oklab, black, black)) {
    .btninput[type="checkbox"]:checked:hover, .btninput[type="radio"]:checked:hover {
      background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
      border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    }
  }

  :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
    cursor: pointer;
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  @supports (color: oklch(0% 0 0)) {
    :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(.active, .btn):hover, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(.active, .btn):hover {
      background-color: var(--fallback-bc, oklch(var(--bc) / .1));
    }
  }
}

.footer {
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

@media (width >= 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  background-position: center;
  background-size: cover;
  place-items: center;
  width: 100%;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-content {
  z-index: 0;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 80rem;
  padding: 1rem;
  display: flex;
}

.input {
  appearance: none;
  border-radius: var(--rounded-btn, .5rem);
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
  border-width: 1px;
  border-color: #0000;
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.input[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.input-md[type="number"]::-webkit-inner-spin-button {
  margin-top: -1rem;
  margin-bottom: -1rem;
  margin-inline-end: -1rem;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu {
  flex-flow: column wrap;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.menu :where(li ul) {
  white-space: nowrap;
  margin-inline-start: 1rem;
  padding-inline-start: .5rem;
  position: relative;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  -webkit-user-select: none;
  user-select: none;
  grid-auto-columns: minmax(auto, max-content) auto max-content;
  grid-auto-flow: column;
  align-content: flex-start;
  align-items: center;
  gap: .5rem;
  display: grid;
}

.menu li.disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  color: var(--fallback-bc, oklch(var(--bc) / .3));
}

.menu :where(li > .menu-dropdown:not(.menu-dropdown-show)) {
  display: none;
}

:where(.menu li) {
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: stretch;
  display: flex;
  position: relative;
}

:where(.menu li) .badge {
  justify-self: end;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > :not(script, style)) {
  align-items: center;
  display: inline-flex;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
    animation: button-pop var(--animation-btn, .25s) ease-out;
  }
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .97));
  animation: ease-out button-pop;
}

@supports not (color: oklch(0% 0 0)) {
  .btn {
    background-color: var(--btn-color, var(--fallback-b2));
    border-color: var(--btn-color, var(--fallback-b2));
  }

  .btn-accent {
    --btn-color: var(--fallback-a);
  }
}

@supports (color: color-mix(in oklab, black, black)) {
  .btn-outline.btn-primary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-p, oklch(var(--p) / 1)) 90%, black);
  }

  .btn-outline.btn-secondary.btn-active {
    background-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-s, oklch(var(--s) / 1)) 90%, black);
  }

  .btn-outline.btn-accent.btn-active {
    background-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-a, oklch(var(--a) / 1)) 90%, black);
  }

  .btn-outline.btn-success.btn-active {
    background-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-su, oklch(var(--su) / 1)) 90%, black);
  }

  .btn-outline.btn-info.btn-active {
    background-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-in, oklch(var(--in) / 1)) 90%, black);
  }

  .btn-outline.btn-warning.btn-active {
    background-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-wa, oklch(var(--wa) / 1)) 90%, black);
  }

  .btn-outline.btn-error.btn-active {
    background-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
    border-color: color-mix(in oklab, var(--fallback-er, oklch(var(--er) / 1)) 90%, black);
  }
}

.btn:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

@supports (color: oklch(0% 0 0)) {
  .btn-accent {
    --btn-color: var(--a);
  }
}

.btn-accent {
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  outline-color: var(--fallback-a, oklch(var(--a) / 1));
  --tw-text-opacity: 1;
}

.btn.glass {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  outline-color: currentColor;
}

.btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn-ghost {
  color: currentColor;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
  outline-color: currentColor;
}

.btn-ghost.btn-active {
  background-color: var(--fallback-bc, oklch(var(--bc) / .2));
  border-color: #0000;
}

.btn-outline {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline.btn-active {
  border-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-border-opacity)));
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  color: var(--fallback-b1, oklch(var(--b1) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.btn-outline.btn-primary {
  color: var(--fallback-p, oklch(var(--p) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-primary.btn-active {
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-secondary {
  color: var(--fallback-s, oklch(var(--s) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-secondary.btn-active {
  color: var(--fallback-sc, oklch(var(--sc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-accent {
  color: var(--fallback-a, oklch(var(--a) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-accent.btn-active {
  color: var(--fallback-ac, oklch(var(--ac) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-success {
  color: var(--fallback-su, oklch(var(--su) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-success.btn-active {
  color: var(--fallback-suc, oklch(var(--suc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-info {
  color: var(--fallback-in, oklch(var(--in) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-info.btn-active {
  color: var(--fallback-inc, oklch(var(--inc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-warning {
  color: var(--fallback-wa, oklch(var(--wa) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-warning.btn-active {
  color: var(--fallback-wac, oklch(var(--wac) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-error {
  color: var(--fallback-er, oklch(var(--er) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn-outline.btn-error.btn-active {
  color: var(--fallback-erc, oklch(var(--erc) / var(--tw-text-opacity)));
  --tw-text-opacity: 1;
}

.btn.btn-disabled, .btn[disabled], .btn:disabled {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  --tw-border-opacity: 0;
  --tw-bg-opacity: .2;
  --tw-text-opacity: .2;
}

.btninput[type="checkbox"]:checked, .btninput[type="radio"]:checked {
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.btninput[type="checkbox"]:checked:focus-visible, .btninput[type="radio"]:checked:focus-visible {
  outline-color: var(--fallback-p, oklch(var(--p) / 1));
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .98));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.checkbox:focus {
  box-shadow: none;
}

.checkbox:focus-visible {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / 1));
}

.checkbox:disabled {
  cursor: not-allowed;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .2;
  --tw-bg-opacity: 1;
  border-width: 0;
  border-color: #0000;
}

.checkbox:checked, .checkbox[aria-checked="true"] {
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-color: var(--chkbg);
  background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%), linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%), linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%), linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%), linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
}

.checkbox:indeterminate {
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  animation: checkmark var(--animation-input, .2s) ease-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(-90deg, transparent 80%, var(--chkbg) 80%), linear-gradient(0deg, var(--chkbg) 43%, var(--chkfg) 43%, var(--chkfg) 57%, var(--chkbg) 57%);
  --tw-bg-opacity: 1;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  background-color: #0006;
}

.drawer-toggle:focus-visible ~ .drawer-content label.drawer-button {
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
}

.input input {
  background-color: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
  background-color: #0000;
}

.input input:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: var(--fallback-bc, oklch(var(--bc) / .2));
  outline-offset: 2px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: var(--fallback-bc, oklch(var(--bc) / .2));
}

.input:has( > input[disabled]), .input-disabled, .input:disabled, .input[disabled] {
  cursor: not-allowed;
  border-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-border-opacity)));
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  color: var(--fallback-bc, oklch(var(--bc) / .4));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
}

.input:has( > input[disabled])::placeholder, .input-disabled::placeholder, .input:disabled::placeholder, .input[disabled]::placeholder {
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-placeholder-opacity)));
  --tw-placeholder-opacity: .2;
}

.input:has( > input[disabled]) > input[disabled] {
  cursor: not-allowed;
}

.input::-webkit-date-and-time-value {
  text-align: inherit;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

:where(.menu li:empty) {
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  --tw-bg-opacity: 1;
  height: 1px;
  margin: .5rem 1rem;
}

.menu :where(li ul):before {
  bottom: .75rem;
  background-color: var(--fallback-bc, oklch(var(--bc) / var(--tw-bg-opacity)));
  opacity: .1;
  content: "";
  inset-inline-start: 0;
  --tw-bg-opacity: 1;
  width: 1px;
  position: absolute;
  top: .75rem;
}

.menu :where(li:not(.menu-title) > :not(ul, details, .menu-title, .btn)), .menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: var(--rounded-btn, .5rem);
  text-align: start;
  text-wrap: balance;
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

:where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > :not(ul, details, .menu-title)):is(summary):not(.active, .btn):focus-visible, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn).focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):not(summary, .active, .btn):focus, :where(.menu li:not(.menu-title, .disabled) > details > summary:not(.menu-title)):is(summary):not(.active, .btn):focus-visible {
  cursor: pointer;
  background-color: var(--fallback-bc, oklch(var(--bc) / .1));
  color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  outline-offset: 2px;
  --tw-text-opacity: 1;
  outline: 2px solid #0000;
}

.menu li > :not(ul, .menu-title, details, .btn):active, .menu li > :not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  background-color: var(--fallback-n, oklch(var(--n) / var(--tw-bg-opacity)));
  color: var(--fallback-nc, oklch(var(--nc) / var(--tw-text-opacity)));
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

.menu :where(li > details > summary)::-webkit-details-marker {
  display: none;
}

.menu :where(li > details > summary):after, .menu :where(li > .menu-dropdown-toggle):after {
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  justify-self: end;
  width: .5rem;
  height: .5rem;
  margin-top: -.5rem;
  transition-property: transform, margin-top;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.menu :where(li > details[open] > summary):after, .menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after {
  margin-top: 0;
  transform: rotate(225deg);
}

.mockup-browser .mockup-browser-toolbar .input {
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  direction: ltr;
  --tw-bg-opacity: 1;
  width: 24rem;
  height: 1.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  display: block;
  position: relative;
  overflow: hidden;
}

.mockup-browser .mockup-browser-toolbar .input:before {
  content: "";
  aspect-ratio: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  --tw-translate-y: -50%;
  border-width: 2px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .75rem;
  position: absolute;
  top: 50%;
  left: .5rem;
}

.mockup-browser .mockup-browser-toolbar .input:after {
  content: "";
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .6;
  --tw-translate-y: 25%;
  --tw-rotate: -45deg;
  border-width: 1px;
  border-color: currentColor;
  border-radius: 9999px;
  height: .5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
}

@keyframes modal-pop {
  0% {
    opacity: 0;
  }
}

@keyframes progress-loading {
  50% {
    background-position-x: -115%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 12px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 3px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset, 0 0 0 4px var(--fallback-b1, oklch(var(--b1) / 1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes skeleton {
  from {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.btn-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.drawer-open > .drawer-toggle {
  display: none;
}

.drawer-open > .drawer-toggle ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
  overscroll-behavior: auto;
  width: auto;
  display: block;
  position: sticky;
}

.drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay), [dir="rtl"] .drawer-open > .drawer-toggle ~ .drawer-side > :not(.drawer-overlay) {
  transform: translateX(0%);
}

.drawer-open > .drawer-toggle:checked ~ .drawer-side {
  pointer-events: auto;
  visibility: visible;
}

.drawer-open > .drawer-side {
  overflow-y: auto;
}

html:has(.drawer-toggle:checked) {
  scrollbar-gutter: stable;
  overflow-y: hidden;
}

.drawer-open > .drawer-toggle ~ .drawer-side > .drawer-overlay {
  cursor: default;
  background-color: #0000;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-1\/3 {
  top: 33.3333%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-svh {
  height: 100svh;
}

.min-h-full {
  min-height: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-full {
  width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-4 {
  gap: 1rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-x-reverse: 0;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-x-reverse: 0;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
  --tw-space-y-reverse: 0;
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border-purple-700 {
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
}

.bg-base-100 {
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
}

.bg-base-200 {
  background-color: var(--fallback-b2, oklch(var(--b2) / var(--tw-bg-opacity)));
  --tw-bg-opacity: 1;
}

.bg-gray-400 {
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-gray-800 {
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-purple-500 {
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-slate-100 {
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.stroke-current {
  stroke: currentColor;
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-400 {
  color: rgb(156 163 175 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-600 {
  color: rgb(75 85 99 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-700 {
  color: rgb(55 65 81 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-800 {
  color: rgb(31 41 55 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-gray-900 {
  color: rgb(17 24 39 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-purple-500 {
  color: rgb(168 85 247 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-purple-700 {
  color: rgb(126 34 206 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

.shadow {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow-lg {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  font-family: Roboto, sans-serif;
}

.bg-white {
  background-color: #fff;
}

.bg-purple {
  background-color: #342741;
}

.svg {
  z-index: 22;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}

@media (width >= 768px) {
  .md\:menu-horizontal {
    flex-direction: row;
    display: inline-flex;
  }

  .md\:menu-horizontal > li:not(.menu-title) > details > ul {
    margin-inline-start: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: 1rem;
    padding-inline-end: .5rem;
    position: absolute;
  }

  .md\:menu-horizontal > li > details > ul:before {
    content: none;
  }

  :where(.md\:menu-horizontal > li:not(.menu-title) > details > ul) {
    border-radius: var(--rounded-box, 1rem);
    background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-bg-opacity: 1;
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  }
}

.hover\:bg-purple-100:hover {
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.hover\:bg-purple-700:hover {
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.hover\:text-white:hover {
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

@media (width >= 768px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }
}
/*# sourceMappingURL=index.58cfaada.css.map */
